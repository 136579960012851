<script>
import { mapGetters } from "vuex";
import PowerBiIframe from "@/components/helexium/PowerBiIframe.vue";
import DropFile from "@/components/helexium/DropFileV2.vue";
import Swal from "sweetalert2";

export default {
  components: {
    PowerBiIframe,
    DropFile,
  },
  name: "sgsst",
  data() {
    return {
      paso: "1",
      fileTypes: [
        "application/pdf",
        "application/docx",
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        "text/plain",
        "image/*",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      texto: [
        {
          id: 1,
          estandar: "RECURSOS (10%)",
          estandar_texto:
            "Recursos financieros, técnicos,  humanos y de otra índole requeridos para coordinar y desarrollar el Sistema de Gestión de la Seguridad y Salud en el Trabajo (SG-SST) (4%) ",
          item_estandar:
            "1.1.1 Responsable del sistema de Gestión de Seguridad y Salud en el trabajo SG-SST",
          popover: "prueba",
          seleccionar: "prueba",
          seleccionar_archivo: "prueba",
          // titulo:"",
        },
        {
          id: 2,
          estandar: "RECURSOS (10%)",
          estandar_texto:
            "Recursos financieros, técnicos,  humanos y de otra índole requeridos para coordinar y desarrollar el Sistema de Gestión de la Seguridad y Salud en el Trabajo (SG-SST) (4%) ",
          item_estandar:
            "1.1.1 Responsable del sistema de Gestión de Seguridad y Salud en el trabajo SG-SST",
          popover: "prueba",
          seleccionar: "prueba",
          seleccionar_archivo: "prueba",
          // titulo:"",
        },
      ],
      items: [{ Areas: [] }, { Areas: [] }, { Areas: [] }, { Areas: [] }],
      file2: null,
      selected: null,
      options: [
        { id: 1, text: "Cumple" },
        { id: 2, text: "No cumple" },
        { id: 3, text: "No aplica" },
      ],
      usuario: {},
      item: {
        EmpresaId: 0,
        SucursalId: "00000000-0000-0000-0000-000000000000",
        FechaIngreso: this.$moment
          .tz(new Date(), "America/Costa_Rica")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        FechaEvaluacion: this.$moment
          .tz(new Date(), "America/Costa_Rica")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        Estado: "",
      },
      listaSucursales: [],
      sucursalSeleccionada: "",
      camposInactivos: true,
      botonNuevaEvaluacion: true,
      file1: null,
      filename1: null,
      files: [],
      filelist: [],
      actualfile: -1,
      listaArchivos: [],
      OpenClose: false,
      aboutSeccionResultados: {},
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.item.EmpresaId = this.usuario.empresa.id;

    await this.getSucursales();
    this.items = await this.getData();

    this.getAboutSeccionResultados();
    this.$isLoading(false);
    //await this.getData();
  },
  computed: {
    ...mapGetters(["userLoggedIn"]),
  },
  methods: {
    getAboutSeccionResultados() {
      const empresaId = this.item.EmpresaId; //this.userLoggedIn.empresa.id;
      this.$store.getters
        .fetchGet({ path: `IFrame/empresa/${empresaId}/seccion/SGSST/last` })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((result) => {
          this.aboutSeccionResultados = result;
        })
        .catch((error) => error);
    },
    gotoFile(it) {
      window.open(
        it.urlArchivo,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500"
      );
    },
    showToast(type, message) {
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    async renameFile(nameFile) {
      this.realName = nameFile;
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    clearVarFiles() {
      this.file1 = null;
      this.finename1 = null;
      this.files = [];
      this.filelist = [];
    },
    onChange(i) {
      this.actualfile = i;
      this.filelist = [...this.$refs["file_" + i].files];
    },
    remove(i, index) {
      this.filelist.dataFiles.splice(index, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, i) {
      event.preventDefault();
      this.files = event;

      // this.$refs.file.files = event.dataTransfer.files;
      this.$refs["file_" + i].files = event.dataTransfer.files;
      this.onChange(i); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    async onFileSelected2(item) {
      try {
        this.$isLoading(true);
        //let path = "/"+"pesv"+"/"+this.usuario.empresa.id+"/"+this.sucursalSeleccionada+"/"+this.item.id+"/"+item.FaseId+"/"+item.Id+"/"
        //empresa,sucursal,pesvevaluacionid,fase,paso

        // !No tiene uso dentro de esta funcion
        // let data = {
        //   empresa: this.usuario.empresa.id,
        //   sucursal: this.sucursalSeleccionada,
        //   SstEvaluacionId: this.item.id,
        //   fase: item.SubAreaId,
        //   paso: item.Id,
        //   PHVAId: this.paso,
        // };

        this.files = this.filelist;
        for await (let it of this.filelist) {
          //let loader = this.$loading.show();
          try {
            this.file1 = it; //event.target.files[0];
            this.filename1 = await this.renameFile(this.file1.name); // Math.floor(Math.random() * 9999999999999 + 1);
            if (this.filename1 != null) {
              // let uploadImg = await this.$store.dispatch("onFileSelectedSst", {
              //   filename: this.filename1,
              //   file: this.file1,
              //   data: data,
              // });
              let url =
                ".|site1|users|sst|evaluaciones|" +
                this.usuario.empresa.id +
                "|" +
                this.sucursalSeleccionada +
                "|" +
                this.item.id +
                "|" +
                this.paso +
                "|" +
                item.SubAreaId +
                "|" +
                item.Id;
              let uploadImg = await this.$store.dispatch("onFileCustom", {
                filename: this.filename1,
                file: this.file1,
                url: url,
              });

              if (uploadImg == true) {
                let data = {
                  PHVAId: this.paso,
                  SstEvaluacionId: this.item.id,
                  PasoId: item.Id,
                  FaseId: item.SubAreaId,
                  UrlArchivo:
                    "https://www.helexiumfiles.com/users/sst/evaluaciones/" +
                    this.usuario.empresa.id +
                    "/" +
                    this.sucursalSeleccionada +
                    "/" +
                    this.item.id +
                    "/" +
                    this.paso +
                    "/" +
                    item.SubAreaId +
                    "/" +
                    item.Id +
                    "/" +
                    this.filename1,
                  NombreArchivo: this.filename1,
                  FechaArchivo: this.$moment
                    .tz(new Date(), "America/Costa_Rica")
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
                };

                await this.$store.dispatch("hl_post", {
                  path: "SstPasosEvaluacion/CreateSstPasoEvaluacionArchivo",
                  data: data,
                });

                this.showToast("success", "Archivo subido correctamente!");
                this.clearVarFiles();
              }
            }
          } catch (error) {
            return error;
          }
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
      }
    },
    async generarPasosEvaluacion(SstEvaluacionId) {
      let data = [];
      this.item.Estado = "Completado";

      for (let index = 0; index < 4; index++) {
        //const element = array[index];

        for await (let it of this.items[index].Areas) {
          for await (let itemSub of it.subAreas) {
            for await (let itemSubIt of itemSub.Items) {
              if (itemSubIt.value != undefined) {
                let itemData = {
                  Id: itemSubIt.SstPasoId,
                  SstEvaluacionId: SstEvaluacionId,
                  PasoId: itemSubIt.Id,
                  CalificacionId: itemSubIt.value,
                  UrlArchivo: "",
                };
                data.push(itemData);
              } else {
                this.item.Estado = "Incompleto";
              }
            }
          }
        }
      }
      return data;
    },
    async saveData() {
      try {
        if (this.sucursalSeleccionada == "") {
          alert("falta sucursal");
          return;
        }
        this.$isLoading(true);

        this.item.SucursalId = this.sucursalSeleccionada;
        this.item.FechaEvaluacion = this.$moment
          .tz(this.item.FechaEvaluacion, "America/Costa_Rica")
          .format("YYYY-MM-DDTHH:mm:ssZ");

        if (this.item.id) {
          let data = await this.generarPasosEvaluacion(this.item.id);

          await this.$store.dispatch("hl_post", {
            path: "SstPasosEvaluacion/CreatePasos/",
            data: data,
          });

          if (this.item.Estado == "Completado") {
            await this.$store.dispatch("hl_post", {
              path: "SstPasosEvaluacion/Update/",
              data: this.item,
            });
            this.items = [];
          }
          this.$isLoading(false);
          Swal.fire(
            "Listo!",
            "Registro almacenado correctamente, Estado de la evaluación:" +
              this.item.Estado,
            "success"
          );
        }
      } catch (error) {
        this.$isLoading(false);

        this.item = {};
      }
    },
    async crearEvaluacion() {
      this.item.Estado = "Incompleto";
      let res = await this.$store.dispatch("hl_post", {
        path: "SstPasosEvaluacion/Create/",
        data: this.item,
      });

      this.item.id = res;
      this.camposInactivos = false;
      this.botonNuevaEvaluacion = true;
    },
    async obtenerEvaluacionSucursal() {
      this.$isLoading(true);
      try {
        //this.items=[]
        let res = await this.$store.dispatch("hl_get", {
          path: "SstPasosEvaluacion/" + this.sucursalSeleccionada,
        });
        this.$isLoading(false);

        if (res.length > 0) {
          this.item = res[0];
          this.item.FechaEvaluacion = this.$moment
            .tz(this.item.FechaEvaluacion, "America/Costa_Rica")
            .format("YYYY-MM-DD");
          //consultando pasos
          let resPasos = await this.$store.dispatch("hl_get", {
            path: "SstPasosEvaluacion/GetPasosEvaluacion/" + this.item.id,
          });

          let items = await this.getData();
          // let c1 = 0;
          // let c2 = 0;
          // let c3 = 0;
          this.items = items;

          for (let index = 0; index < 4; index++) {
            for await (let it of this.items[index].Areas) {
              for await (let itemSub of it.subAreas) {
                for await (let itemSubIt of itemSub.Items) {
                  for await (let itemPaso of resPasos) {
                    if (itemSubIt.Id == itemPaso.pasoId) {
                      itemSubIt.value = itemPaso.calificacionId;
                      itemSubIt.SstPasoId = itemPaso.id;
                    }
                  }
                  // c3++;
                }
                // c2++;
              }
              // c1++;
            }
          }

          Swal.fire(
            "Atención!",
            "Encontramos una evaluación en proceso para esta sucursal.",
            "info"
          );
          this.camposInactivos = false;
          this.botonNuevaEvaluacion = true;
          this.$forceUpdate();
        } else {
          this.items = await this.getData();

          this.item = {
            EmpresaId: this.usuario.empresa.id,
            SucursalId: this.sucursalSeleccionada,
            FechaIngreso: this.$moment
              .tz(new Date(), "America/Costa_Rica")
              .format("YYYY-MM-DD"),
            FechaEvaluacion: this.$moment
              .tz(new Date(), "America/Costa_Rica")
              .format("YYYY-MM-DD"),
            Estado: "",
          };
          this.$isLoading(false);
          Swal.fire(
            "Atención!",
            "No hay evaluaciones en proceso para esta sucursal.",
            "info"
          );
          this.camposInactivos = true;
          this.listaArchivos = [];
          this.botonNuevaEvaluacion = false;
        }
      } catch (error) {
        this.item = {
          EmpresaId: this.usuario.empresa.id,
          SucursalId: this.sucursalSeleccionada,
          FechaIngreso: this.$moment
            .tz(new Date(), "America/Costa_Rica")
            .format("YYYY-MM-DD"),
          FechaEvaluacion: this.$moment
            .tz(new Date(), "America/Costa_Rica")
            .format("YYYY-MM-DD"),
          Estado: "",
        };
        this.$isLoading(false);

        this.camposInactivos = true;
        this.botonNuevaEvaluacion = false;
      }
    },
    async getSucursales() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
        });

        if (res.length > 0) {
          this.listaSucursales = res;
        } else {
          this.listaSucursales = [];
        }
      } catch (error) {
        this.listaSucursales = [];
      }
    },

    async getListaArchivos() {
      if (this.item.id == undefined || this.item.id == null) {
        this.listaArchivos = [];
        return;
      }
      this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "SstPasosEvaluacion/GetSstListaArchivos/" + this.item.id,
        });

        this.$isLoading(false);

        if (res.length > 0) {
          this.listaArchivos = res;
        } else {
          this.listaArchivos = [];
        }
      } catch (error) {
        this.$isLoading(false);
      }
    },
    async getData() {
      // this.$isLoading(true);

      try {
        let res = await this.$store.dispatch("getw", { path: "sst/" });
        // this.$isLoading(false);
        if (res.length > 0) {
          await this.getListaArchivos();
          return res;
        } else {
          return [];
        }
      } catch (error) {
        //loader.hide();
        // this.$isLoading(false);
        return [];
        //console.log("err", error);
      }
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>Sistema de Gestión : SGSST</b></h4>
          </template>
          <div class="row text-center pt-4 pb-2">
            <div
              v-for="itemSst of items"
              :key="itemSst.id"
              :class="
                itemSst.Id == 1
                  ? 'col-xl-2 col-md-2 col-sm-4  offset-sm-0'
                  : 'col-xl-2 col-md-2 col-sm-4'
              "
            >
              <button
                class="btn-evaluacion btn btn-circle btn-secondary"
                :class="paso == itemSst.Id ? 'active' : ''"
                @click="paso = itemSst.Id"
              >
                <span class="h3">{{ itemSst.Id }}</span>
              </button>
              <p class="pt-3 h4">{{ itemSst.ItemPHVA }}</p>
            </div>

            <div class="col-xl-2 col-md-2 col-sm-4">
              <button
                @click="
                  paso = 5;
                  getListaArchivos();
                "
                class="btn-evaluacion btn btn-circle btn-secondary"
                :class="paso == 5 ? 'active' : ''"
              >
                <span class="h3">5</span>
              </button>
              <p class="pt-3 h4">Archivos SGSST</p>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-4">
              <button
                class="btn-evaluacion btn btn-circle btn-secondary"
                :class="paso == 6 ? 'active' : ''"
                @click="paso = 6"
              >
                <span class="h3">6</span>
              </button>
              <p class="pt-3 h4">Resultados</p>
            </div>
          </div>

          <div class="row p-4">
            <div class="col-6">
              <label for="sucursal">Sucursal:</label><br />
              <b-form-select
                @change.native="obtenerEvaluacionSucursal()"
                id="sucursal"
                v-model="sucursalSeleccionada"
                value-field="id"
                text-field="descripcion"
                :options="listaSucursales"
              ></b-form-select>
            </div>
            <div class="col-6">
              <div>
                <label for=" " class="form-label">Fecha Evaluación</label>
                <input
                  v-model="item.FechaEvaluacion"
                  placeholder=""
                  type="date"
                  class="form-control "
                />
              </div>
            </div>
            <div class="col-6 mt-2">
              <div v-if="item.Estado">
                <label for=" " class="form-label mr-2 mt-2"
                  >Estado de la evaluación:</label
                >
                <span
                  :class="`text-uppercase font-weight-bold border py-2 px-2 rounded ${
                    item.Estado == 'Completo' ? 'text-primary' : 'text-warning'
                  }`"
                >
                  <i
                    :class="`fas fa-${
                      item.Estado == 'Completo'
                        ? 'thumbs-up'
                        : 'exclamation-triangle'
                    }`"
                  ></i>
                  {{ item.Estado }}</span
                >
              </div>
            </div>

            <div class="col-12">
              <div>
                <vs-button
                  type="submit"
                  success
                  :disabled="botonNuevaEvaluacion"
                  @click.prevent="crearEvaluacion"
                >
                  <i class="fas fa-plus pr-1"></i>
                  Nueva Evaluación
                </vs-button>
              </div>
            </div>
          </div>
        </card>
        <form @submit.prevent="saveData()">
          <card v-if="paso < 5">
            <div class="text-left p-4">
              <vs-button
                type="submit"
                :disabled="camposInactivos"
              >
                <i class="fas fa-check pr-1"></i>
                Guardar Evaluación
              </vs-button>
            </div>

            <div v-if="paso == 1" class="text-left p-4">
              <span class="text-secondary d-block font-weight-bold">
                Ciclo
              </span>
              <div
                class="mt-3 mb-3"
                v-for="Item in items[0].Areas"
                :key="Item.id"
              >
                <p>
                  <b>{{ Item.Area }}</b>
                </p>
                <div
                  v-for="it in Item.subAreas"
                  :key="it.Id"
                  class="card border border-light p-3"
                >
                  <span>{{ it.SubArea }}</span>

                  <div v-for="(itf, ii) in it.Items" :key="itf.id" class="mt-3">
                    <div class="pl-2 d-flex align-items-center">
                      <h5 class="font-weight-bold h6 pt-2">
                        {{ itf.ItemDelEstandar }}
                      </h5>
                      <div class="popover__wrapper">
                        <vs-tooltip>
                          <vs-button transparent icon>
                            <i class="fas fa-info-circle text-secondary"> </i>
                          </vs-button>
                          <template #tooltip>
                            {{ itf.tooltip }}
                          </template>
                        </vs-tooltip>
                      </div>
                    </div>

                    <div class="mt-3">
                      <b-form-select
                        class="col-4"
                        :disabled="camposInactivos"
                        v-model="itf.value"
                        value-field="id"
                        text-field="text"
                        :options="options"
                        :key="itf.Id"
                        :id="'select-' + itf.Id + ii"
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!itf.value"
                            selected
                            :value="none"
                            disabled
                          >
                            -- selecciona una opción --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="mt-3 mb-3">
                        <span class="text-light"
                          >Cargar plan de mejoramiento (Opcional)</span
                        >
                      </div>
                      <div v-if="!camposInactivos">
                        <DropFile
                          :urlDir="`users/sst/evaluaciones/${usuario.empresa.id}/${sucursalSeleccionada}/${item.id}/${paso}/${itf.SubAreaId}/${itf.Id}`"
                          :pathGet="`SstPasosEvaluacion/GetSstListaArchivosId/${item.id}/${itf.Id}/${itf.SubAreaId}/${paso}`"
                          pathPost="SstPasosEvaluacion/CreateSstPasoEvaluacionArchivo"
                          :injectData="{
                            PHVAId: Number(paso),
                            SstEvaluacionId: item.id,
                            PasoId: itf.Id,
                            FaseId: itf.SubAreaId,
                            FechaArchivo: $moment
                              .tz(new Date(), 'America/Costa_Rica')
                              .format('YYYY-MM-DDTHH:mm:ssZ'),
                          }"
                          :fileTypes="fileTypes"
                        />
                      </div>
                    </div>
                    <br />
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="paso == 2" class="text-left p-4">
              <span class="text-secondary d-block font-weight-bold">
                Ciclo
              </span>
              <div
                class="mt-3 mb-3"
                v-for="Item in items[1].Areas"
                :key="Item.id"
              >
                <p>
                  <b>{{ Item.Area }}</b>
                </p>
                <div
                  v-for="it in Item.subAreas"
                  :key="it.Id"
                  class="card border border-light p-3"
                >
                  <span>{{ it.SubArea }}</span>

                  <div v-for="(itf, ii) in it.Items" :key="itf.id" class="mt-3">
                    <div class="pl-2 d-flex align-items-center">
                      <h5 class="font-weight-bold h6 pt-2">
                        {{ itf.ItemDelEstandar }}
                      </h5>
                      <div class="popover__wrapper">
                        <vs-tooltip>
                          <vs-button transparent icon>
                            <i class="fas fa-info-circle text-secondary"> </i>
                          </vs-button>
                          <template #tooltip>
                            {{ itf.tooltip }}
                          </template>
                        </vs-tooltip>
                      </div>
                    </div>
                    <div class="mt-3">
                      <b-form-select
                        class="col-4"
                        :disabled="camposInactivos"
                        v-model="itf.value"
                        value-field="id"
                        text-field="text"
                        :options="options"
                        :key="itf.Id"
                        :id="'select-' + itf.Id + ii"
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!itf.value"
                            selected
                            :value="none"
                            disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <div class="mt-3 mb-3">
                        <span class="text-light"
                          >Cargar plan de mejoramiento (Opcional)</span
                        >
                      </div>
                      <div v-if="!camposInactivos">
                        <DropFile
                          :urlDir="`users/sst/evaluaciones/${usuario.empresa.id}/${sucursalSeleccionada}/${item.id}/${paso}/${itf.SubAreaId}/${itf.Id}`"
                          :pathGet="`SstPasosEvaluacion/GetSstListaArchivosId/${item.id}/${itf.Id}/${itf.SubAreaId}/${paso}`"
                          pathPost="SstPasosEvaluacion/CreateSstPasoEvaluacionArchivo"
                          :injectData="{
                            PHVAId: Number(paso),
                            SstEvaluacionId: item.id,
                            PasoId: itf.Id,
                            FaseId: itf.SubAreaId,
                            FechaArchivo: $moment
                              .tz(new Date(), 'America/Costa_Rica')
                              .format('YYYY-MM-DDTHH:mm:ssZ'),
                          }"
                          :fileTypes="fileTypes"
                        />
                      </div>
                    </div>
                    <br />
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="paso == 3" class="text-left p-4">
              <span class="text-secondary d-block font-weight-bold">
                Ciclo
              </span>
              <div
                class="mt-3 mb-3"
                v-for="Item in items[2].Areas"
                :key="Item.id"
              >
                <p class="">
                  <b>{{ Item.Area }}</b>
                </p>
                <div
                  v-for="it in Item.subAreas"
                  :key="it.Id"
                  class="card border border-light p-3"
                >
                  <span class="">{{ it.SubArea }}</span>

                  <div v-for="(itf, ii) in it.Items" :key="itf.id" class="mt-3">
                    <div class="pl-2 d-flex align-items-center">
                      <h5 class="font-weight-bold h6 pt-2">
                        {{ itf.ItemDelEstandar }}
                      </h5>
                      <div class="popover__wrapper">
                        <vs-tooltip>
                          <vs-button transparent icon>
                            <i class="fas fa-info-circle text-secondary"> </i>
                          </vs-button>
                          <template #tooltip>
                            {{ itf.tooltip }}
                          </template>
                        </vs-tooltip>
                      </div>
                    </div>
                    <div class="mt-3">
                      <b-form-select
                        class="col-4"
                        :disabled="camposInactivos"
                        v-model="itf.value"
                        value-field="id"
                        text-field="text"
                        :options="options"
                        :key="itf.Id"
                        :id="'select-' + itf.Id + ii"
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!itf.value"
                            selected
                            :value="none"
                            disabled
                          >
                            -- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <div class="mt-3 mb-3">
                        <span class="text-light"
                          >Cargar plan de mejoramiento (Opcional)</span
                        >
                      </div>
                      <div v-if="!camposInactivos">
                        <DropFile
                          :urlDir="`users/sst/evaluaciones/${usuario.empresa.id}/${sucursalSeleccionada}/${item.id}/${paso}/${itf.SubAreaId}/${itf.Id}`"
                          :pathGet="`SstPasosEvaluacion/GetSstListaArchivosId/${item.id}/${itf.Id}/${itf.SubAreaId}/${paso}`"
                          pathPost="SstPasosEvaluacion/CreateSstPasoEvaluacionArchivo"
                          :injectData="{
                            PHVAId: Number(paso),
                            SstEvaluacionId: item.id,
                            PasoId: itf.Id,
                            FaseId: itf.SubAreaId,
                            FechaArchivo: $moment
                              .tz(new Date(), 'America/Costa_Rica')
                              .format('YYYY-MM-DDTHH:mm:ssZ'),
                          }"
                          :fileTypes="fileTypes"
                        />
                      </div>
                    </div>
                    <br />
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="paso == 4" class="text-left p-4">
              <span class="text-secondary d-block font-weight-bold">
                Ciclo
              </span>
              <div
                class="mt-3 mb-3"
                v-for="Item in items[3].Areas"
                :key="Item.id"
              >
                <p class="font-weight-bold">
                  {{ Item.Area }}
                </p>
                <div
                  v-for="it in Item.subAreas"
                  :key="it.Id"
                  class="card border border-light p-3"
                >
                  <span>{{ it.SubArea }}</span>

                  <div v-for="(itf, ii) in it.Items" :key="itf.id" class="mt-3">
                    <div class="pl-2 d-flex align-items-center">
                      <h5 class="font-weight-bold h6 pt-2">
                        {{ itf.ItemDelEstandar }}
                      </h5>
                      <div class="popover__wrapper">
                        <vs-tooltip>
                          <vs-button transparent icon>
                            <i class="fas fa-info-circle text-secondary"> </i>
                          </vs-button>
                          <template #tooltip>
                            {{ itf.tooltip }}
                          </template>
                        </vs-tooltip>
                      </div>
                    </div>
                    <div class="mt-3">
                      <b-form-select
                        class="col-4"
                        :disabled="camposInactivos"
                        v-model="itf.value"
                        value-field="id"
                        text-field="text"
                        :options="options"
                        :key="itf.Id"
                        :id="'select-' + itf.Id + ii"
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!itf.value"
                            selected
                            :value="none"
                            disabled
                            >-- selecciona una opción --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <div class="mt-3 mb-3">
                        <span class="text-light"
                          >Cargar plan de mejoramiento (Opcional)</span
                        >
                      </div>
                      <div v-if="!camposInactivos">
                        <DropFile
                          :urlDir="`users/sst/evaluaciones/${usuario.empresa.id}/${sucursalSeleccionada}/${item.id}/${paso}/${itf.SubAreaId}/${itf.Id}`"
                          :pathGet="`SstPasosEvaluacion/GetSstListaArchivosId/${item.id}/${itf.Id}/${itf.SubAreaId}/${paso}`"
                          pathPost="SstPasosEvaluacion/CreateSstPasoEvaluacionArchivo"
                          :injectData="{
                            PHVAId: Number(paso),
                            SstEvaluacionId: item.id,
                            PasoId: itf.Id,
                            FaseId: itf.SubAreaId,
                            FechaArchivo: $moment
                              .tz(new Date(), 'America/Costa_Rica')
                              .format('YYYY-MM-DDTHH:mm:ssZ'),
                          }"
                          :fileTypes="fileTypes"
                        />
                      </div>
                    </div>
                    <br />
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </card>
        </form>

        <card>
          <div v-if="paso == 5" class="text-center p-4">
            <h3>LISTA DE ARCHIVOS</h3>
            <div>
              <b-tabs content-class="mt-3">
                <b-tab :title="'Fase ' + n" v-for="n in 4" :key="n">
                  <table class="table table-borderless text-left">
                    <thead>
                      <tr>
                        <th scope="col">Nombre del archivo</th>
                        <th scope="col">Paso</th>
                        <th scope="col">Fecha</th>
                        <!-- <th scope="col">Acciones</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="it of listaArchivos">
                        <tr
                          class="border-bottom"
                          :key="it.id"
                          v-if="it.phvaId == n"
                        >
                          <td>
                            <span @click="gotoFile(it)" class="pointer">
                              {{ it.nombreArchivo }}
                            </span>
                          </td>
                          <td>
                            <span> {{ it.pasoId }} </span>
                          </td>
                          <td>
                            <span>
                              {{
                                $moment
                                  .tz(it.fechaArchivo, "America/Costa_Rica")
                                  .format("YYYY-MM-DDHH: mm")
                              }}
                            </span>
                          </td>
                          <td class="h4 text-center">
                            <span class="px-1">
                              <font-awesome-icon
                                @click="gotoFile(it)"
                                class="text-primary text-warning"
                                icon="eye"
                              />
                            </span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </card>
        <card v-if="paso == 6">
          <div class="text-center p-4">
            <PowerBiIframe
              class="container-iframe"
              title="Reporte SGSST"
              :user="userLoggedIn"
              :reportData="aboutSeccionResultados"
            ></PowerBiIframe>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.container-iframe {
  width: 100%;
  min-height: 70vh;
  position: relative;
}

.btn-evaluacion {
  border: solid 3px #16a275;
}

.btn-evaluacion:hover {
  border-color: #12d1df;
}

.btn-evaluacion.active {
  border-color: #df122e;
}
</style>
